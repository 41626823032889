<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title primary-color">{{cvCardTitle}}</h4>
            <p class="card-title">{{cvCardDesc}}</p>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Backend Search -->
              <b-col class="col-12 col-sm-8 col-md-6 col-lg-5 mb-2">
                <b-form-input class="form-control"
                  @input="searchblogWithParams"
                  v-model="whereFilter.search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->
              <b-col v-for="(item, index) in blogObjList" :key="index" class="mobileNoPadding col-12 mt-2">
                <iq-card class="rounded iq-card-block iq-card-stretch iq-card-height">
                  <b-card-body class="iq-card-body rsvpCardBody pt-2">
                    <div class="webKitBox row">
                      <div class="headingBar events-detail w-100 col-12" style="cursor: pointer;">
                        <h4 class="twoLineOnly blog_title mb-1" @click="openUrlInNewTab(`/blog/${item.blog_id}`)">{{ item.blog_title }}</h4>
                        <b-button v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="font-size-20 pull-right" variant="mr-1 mb-1" size="sm" @click="blogEdit(item)">
                          <i class="ri-ball-pen-fill m-0"></i>
                        </b-button>
                      </div>
                      <div class="events-detail twoLineOnly col-12" v-html="item.blog_desc"></div>
                    </div>

                    <div class="mt-2">
                      <span @click="openUrlInNewTab(`/blog/${item.blog_id}`)" class="primary-color float-right font-size-12">Read More<i class="fa fa-arrow-right ml-1 pointer" aria-hidden="true"></i></span>
                    </div>

                    <div v-if="item.blog_keywords">
                        <b-badge
                          v-for="(keywords, index) in item.blog_keywords.split(',')"
                          :key="index"
                          title="keywords"
                          class="text-wrap mr-2"
                          style="background-color: #e5252c !important; margin-top: 10px"
                        >
                        {{ keywords }}
                      </b-badge>
                    </div>
                  </b-card-body>
                </iq-card>
              </b-col>
            </b-row>
            <b-row>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <!-- Gide BLog Edit -->
    <b-modal
      v-model="showModelblogEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xxl"
    >
      <blogEdit :propOpenedInModal="true" :propblogObj="blogEditObj" @emitCloseblogEditModal="closeblogEditModal()" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeblogEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal><!-- Gide BLog Edit -->

    <b-row>
      <b-col sm="12" md="12" class="my-1">
        <b-row>
          <b-col sm="12" md="7">
            <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
          </b-col>
          <b-col sm="5" md="5">
            <b-pagination
              v-model="currentBatchNo"
              :total-rows="totalRows"
              :per-page="dataPerPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { blogs } from "../../../FackApi/api/blog.js"
import { socialvue } from "../../../config/pluginInit.js"
import blogEdit from "../Blog/blogEdit.vue"

export default {
  name: "GIDEPreneur",
  components: {
    blogEdit
  },
  data () {
    return {
      cvCardTitle: "GIDEPreneur",
      cvCardDesc: "GIDEPRENEUR is an innovative program powered by GIDE.AI, designed to train, enable, and empower educators, tutors, and consultants across India to become certified career counselors. With our AI-driven SaaS platform and comprehensive training, you'll have the tools to guide students toward brighter futures while advancing your own career or business. Below is the resources section to support the knowledge enhancement of each one of you - GIDEPreneurs",
      blogObjList: [],
      filter: null,
      filterOn: [],
      whereFilter: {
        search_param: ""
      },
      currentBatchNo: 1,
      blog_category: "GIDEPreneur",
      showLoadMoreBtn: true,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GIDEPreneur Resources",
      cvbtnModalCancel: "Close",
      cvEditModalHeader: "Edit GIDEPreneur Resources",
      cvSearchText: "Type to Search",
      blogKeywords: [],
      showModelblogEdit: false,
      blogEditObj: null,
      dataPerPage: 25,
      totalRows: 1
    }
  },
  beforeMount () {
    // Auth Access for Current User Role.
    if (!(this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11116" || this.userData.user_role == "USERROLE11118")) {
      this.$router.back()
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    socialvue.index()
    this.blogList()
  },
  methods: {
    /**
     * blogList
     */
    async blogList (loadViaLoadMore = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.blog_category = this.blog_category
        this.whereFilter.deleted = "03" // Public, Private Both
        let blogListResp = await blogs.blogList(this, this.whereFilter)
        if (blogListResp.resp_status) {
          this.blogObjList = [ ...this.blogObjList, ...blogListResp.resp_data.data ]
          if (this.whereFilter.search_param && this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.blogObjList = [ ...blogListResp.resp_data.data ]
          }
          this.showLoadMoreBtn = true
        }
        else {
          this.showLoadMoreBtn = false
          this.currentBatchNo = this.currentBatchNo - 1
          this.toastMsg = blogListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at blogList() and Exception:", err)
      }
    },
    /**
     * openUrlInNewTab
     */
    openUrlInNewTab (url) {
      window.open(url, "_blank")
    },
    /**
     * searchblogWithParams
     */
    async searchblogWithParams () {
      if (this.whereFilter.search_param.length <= 3) {
        return
      }

      this.currentBatchNo = 1
      this.blogList()
    },
    /**
     * blogEdit
     */
    blogEdit (item) {
      try {
        this.blogEditObj = item
        this.showModelblogEdit = true
      }
      catch (err) {
        console.error("Exception occurred at blogEdit() and Exception:", err.message)
      }
    },
    /**
     * closeblogEditModal
     */
    closeblogEditModal () {
      try {
        this.blogList()
        this.showModelblogEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeblogEditModal() and Exception:", err.message)
      }
    },
    /**
     * loadMoreData
    */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.blogList(true)
    }
  }
}
</script>
<style>
  .headingBar{
    display: flex;
    justify-content: space-between;
  }
</style>
